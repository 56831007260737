// 
// Chat.scss
//



.user-chat-nav{
  
    .nav-btn{
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu{
      box-shadow: $box-shadow;
      border: 1px solid $border-color
    }
  
}