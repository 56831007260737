.blockUI.blockOverlay{
    z-index: 1000;
      border: none;
      margin: 0px;
      padding: 0px;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background-color: #ccc;
      opacity: 0.2;
      cursor: wait;
      position: absolute;
  }
  
  .blockUI.blockMsg{
    z-index: 1011;
    position: absolute;
    padding: 0px;
    margin: 0px;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: black;
    cursor: wait;
  }
  
  .loader {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite
    }
    .loader::before , .loader::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #333;
      animation: prixClipFix 2s linear infinite ;
    }
    .loader::after{
      inset: 8px;
      transform: rotate3d(90, 90, 0, 180deg );
      border-color: #FF3D00;
    }
  
    @keyframes rotate {
      0%   {transform: rotate(0deg)}
      100%   {transform: rotate(360deg)}
    }
  
    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    }