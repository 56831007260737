//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 28px !important;
    margin: 0 2px !important;
    border: none;
    width: 28px;
    height: 28px;
    padding: 0;
    text-align: center;
    line-height: 28px;
    padding-left: 5px;
    padding-right: 5px;
    /*border-radius: 30px !important;
        margin: 0 3px !important;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
        padding-left: 10px;
        padding-right: 10px;*/
  }
}
